import {
  AnnounceApi,
  locationOfCourse,
  milestone,
  TeacherApi,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { ParsedUrlQuery } from 'querystring';

import { AuthenticationType } from '@/components/Authentication/Authentication.utils';
import { urls } from '@/shared/Hooks/useNavigation';

const shouldNotRedirect = (query: ParsedUrlQuery) =>
  !!query.redirect && query.redirect === 'false';

const getOnboardingRedirectionUrl = ({
  authentication,
  pathname,
  query,
  milestonesStatus,
  teacherAnnounces,
}: {
  authentication: AuthenticationType;
  pathname: string;
  query: ParsedUrlQuery;
  milestonesStatus?: TeacherApi['private']['getMilestonesStatus']['response'];
  teacherAnnounces?: AnnounceApi['private']['getTeacherAnnounces']['response'];
}): string | undefined => {
  const { redirectUrl } = query;

  /** partie étudiant */
  const isLoggedInAsStudent =
    authentication?.isAuthenticated &&
    authentication.authData.userType === UserType.Student;
  const isLoggedInAsTeacher =
    authentication?.isAuthenticated &&
    authentication.authData.userType === UserType.Teacher;
  const isLoggedIn = isLoggedInAsStudent || isLoggedInAsTeacher;
  const isStudentProfileComplete =
    authentication?.authData?.address && authentication?.authData?.phoneNumber;
  /** ************************* */

  /** partie professeur */
  const hasTeacherCompletedHisProfile =
    milestonesStatus?.profileCompletionPercentage >= 100;
  const hasNoAnnounceAtHome =
    teacherAnnounces?.count > 0 &&
    teacherAnnounces?.announces.every((announce) =>
      announce.courseLocations.every(
        (location) => location.location !== locationOfCourse.atHome
      )
    );

  const novaDetails = milestonesStatus?.milestones.find(
    ({ title }) => title === milestone.novaDetails
  );
  const hasNotCompletedNova =
    novaDetails !== undefined &&
    novaDetails.steps.every(({ isCompleted }) => !isCompleted);
  /** ************************* */

  if (shouldNotRedirect(query)) {
    return undefined;
  }
  /** partie non connecté */

  if (!isLoggedIn) {
    return undefined;
  }

  /** partie étudiant */

  if (isLoggedInAsStudent && !isStudentProfileComplete) {
    return `/onboarding-student`;
  }

  if (isLoggedInAsStudent && redirectUrl) {
    return `${redirectUrl}`;
  }

  if (
    isLoggedInAsStudent &&
    isStudentProfileComplete &&
    query.method === 'login' &&
    !redirectUrl
  ) {
    return '/professeurs';
  }

  /** partie professeur */
  if (isLoggedInAsTeacher && !authentication.authData.isEmailVerified) {
    return urls.sherpaSignUp({ step: 5 });
  }

  const isALoginRedirection = pathname === urls.home();
  if (
    isLoggedInAsTeacher &&
    isALoginRedirection &&
    (hasTeacherCompletedHisProfile ||
      (hasNoAnnounceAtHome && hasNotCompletedNova))
  ) {
    return urls.dashboardSummary({ redirect: false });
  }

  if (
    isLoggedInAsTeacher &&
    isALoginRedirection &&
    !hasTeacherCompletedHisProfile
  ) {
    return urls.gettingStarted();
  }

  return undefined;
};

export default getOnboardingRedirectionUrl;
